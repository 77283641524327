import React, { useEffect, useRef, useState } from 'react';
import { YMaps, Map, Polygon, GeolocationControl, ZoomControl, Placemark, useYMaps } from '@pbe/react-yandex-maps';
import { toast } from 'react-toastify';
import pin from "../../images/22.png";

const YMapsComponent2 = ({ setAddress, info, setAddressValue, locationSelect }) => {
    const mapRef = useRef(null);

    const [center, setCenter] = useState();
    const [location, setLocation] = useState();

    const ymaps = useYMaps(['Map']);

    useEffect(() => {
        if(info && info.json_address){
            if(info.json_address.lat && info.json_address.lng){
                setLocation([info.json_address.lat, info.json_address.lng])
            }
        }
    }, [info])

    useEffect(() => {
        if (localStorage.getItem('city')) {
            const city = JSON.parse(localStorage.getItem('city'));
            city?.location?.length >= 1
                ? setCenter(city.location)
                : setCenter([51.1729850769043, 71.42532348632812]);
        }
    }, []);

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation([latitude, longitude]);
                },
                (error) => {
                    toast.error('Ошибка при получении местоположения:', error.message);
                }
            );
        } else {
            toast.error('Геолокация не поддерживается вашим браузером.');
        }
    };

    useEffect(() => {
        getLocation();
    }, []);

    useEffect(() => {
        if(locationSelect){
            setLocation(locationSelect)
        }
    }, [locationSelect])

    const handleSetAddress = async(coords) =>{
        if (ymaps) {
            try {
                const result = await ymaps.geocode(coords, { kind: 'house' });
                const firstGeoObject = result.geoObjects?.get(0);
                if (firstGeoObject) {
                    setAddressValue(firstGeoObject.properties?.get('name'));
                    setAddress({
                        address: firstGeoObject.properties?.get('name'),
                        lat: coords[0],
                        lng: coords[1]
                    })
                } else {
                    toast.error('Адрес не найден');
                }
            } catch (error) {
                console.error('Ошибка геокодирования:', error);
                toast.error('Ошибка при определении адреса');
            }
        }
    }

    useEffect(() => {
        if (location && mapRef.current) {
            const map = mapRef.current;
            map.setCenter(location, 14, { duration: 1000 });
            handleSetAddress(location)
        }
    }, [location, mapRef.current]);

    const handleMapClick = async (event) => {
        const coords = event.get('coords');
        setLocation(coords);
        handleSetAddress(coords)
    };

    return (
        center && (
            <div>
                <Map
                    instanceRef={mapRef}
                    className="main-map"
                    defaultState={{
                        center: center,
                        zoom: 12,
                        controls: [],
                        type: 'yandex#map'
                    }}
                    onClick={handleMapClick}
                    modules={['geocode']}
                    options={{
                        suppressMapOpenBlock: true,
                        copyrightLogoVisible: false,
                        copyrightProvidersVisible: false,
                    }}
                >
                    <ZoomControl options={{ float: 'right' }} />
                    <Placemark
                        geometry={location}
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: pin,
                            iconImageSize: [41, 41],
                        }}
                    />
                </Map>
            </div>
        )
    );
};

export default YMapsComponent2;
