import React, { useContext, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import { toast } from 'react-toastify';

const DeliveUserItems = observer(({CustomSubmit, addressValue, setAddressValue, setLocation}) => {
    const {app} = useContext(Context);

    const {register} = useFormContext();

    const dropdown = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdown, false);

    const [typingTimeout, setTypingTimeout] = useState(0);

    const [availableAddresses, setAvailableAddresses] = useState();

    const handleChange = (e) => {
        setIsActive(true);
        setAddressValue(e.target.value);

        clearTimeout(typingTimeout);
        if(app.yandexGeoApiKey.params?.key?.value){
            setTypingTimeout(setTimeout(async () => {
                const fullQuery = `${e.target.value},+${app.city ? app.city.label : ''}`

                let str =  fullQuery.replace(' ', '+');
                const apiKey = app.yandexGeoApiKey.params?.key?.value;
                const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&geocode=${encodeURIComponent(str)}&format=json`;

                try {
                    const response = await fetch(url);
                    const data = await response.json();
                if (data.response && data.response.GeoObjectCollection.featureMember.length > 0) {
                    const found = data.response.GeoObjectCollection.featureMember.map((item) => ({
                        name: item.GeoObject.name,
                        description: item.GeoObject.description,
                        coordinates: item.GeoObject.Point.pos.split(' '),
                    }));
                    setIsActive(true)
                    setAvailableAddresses(found);
                } else {
                    toast.error('Адрес не найден');
                    setAvailableAddresses([]);
                }
                } catch (error) {
                console.error('Ошибка поиска:', error);
                toast.error('Произошла ошибка при запросе');
                }

            }, 700));
        }
    }

    const handleSuggestionClick = async (el) => {
        setIsActive(false);
        setAddressValue(el.name);
        setLocation([el?.coordinates[1], el?.coordinates[0]])
    }
    
  return (
    <>
        <h3 className='mob-none'>{app.localizationsItems?.new_address}</h3>
        <div className='search-values-container'>
            <input 
                value={addressValue} 
                onChange={e => handleChange(e)} 
                placeholder={app.localizationsItems?.address} 
                className="input input-center"
            ></input>
            {isActive && <div className='search-values-items' ref={dropdown}>
                {availableAddresses?.map((el, index) =>
                    <div className='search-values-item' key={index} onClick={() => handleSuggestionClick(el)}>
                        <div>{el?.name}</div>
                        <div className='search-values-item-sub'>{el?.description}</div>
                    </div>
                )}
            </div>}
        </div>
        <div className="inputs-grid">
            <input type="text" className="input" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
            <input type="text" className="input" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
            <input type="text" className="input" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
            <input type="text" className="input" {...register('code')} placeholder={app.localizationsItems?.code_domofon}/>
        </div>
        <div onClick={CustomSubmit} className='main-btn'>{app.localizationsItems?.save}</div> 
    </>
  )
})

export default DeliveUserItems