import React, { useEffect, useRef, useState } from 'react';
import { Map, Placemark } from '@pbe/react-yandex-maps';
import pin from "../../images/22.png";
import { observer } from 'mobx-react-lite';

const YMapComponentPickups = observer(({pickupAddresses, pickupItem, setPickupItem}) => {
    const mapRef = useRef();
    const [center, setCenter] = useState();

    useEffect(() => {
        if (localStorage.getItem('city')) {
            const city = JSON.parse(localStorage.getItem('city'));
            city?.location?.length >= 1
                ? setCenter(city.location)
                : setCenter([51.1729850769043, 71.42532348632812]);
        }
    }, [localStorage.getItem('city')]);

    useEffect(() => {
        if (pickupItem?.location && mapRef.current) {
            mapRef.current.setCenter(pickupItem.location, 14, { duration: 1000 });
        }
    }, [pickupItem, mapRef.current])

  return (
    center &&
    <div>
        <Map
            instanceRef={mapRef}
            className="main-map"
            defaultState={{
                center: center,
                zoom: 12,
                controls: [],
                type: 'yandex#map'
            }}
            options={{
                suppressMapOpenBlock: true,
                copyrightLogoVisible: false,
                copyrightProvidersVisible: false
            }}
        >
            {pickupAddresses?.items?.map((pickup, index) =>
                <Placemark 
                    geometry={pickup.location} 
                    key={index}
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: pin,
                        iconImageSize: [41, 41],
                    }}
                    eventHandlers={{
                        click: (e) => {
                            setPickupItem(pickup);
                        }
                    }}
                >
                    
                </Placemark>
            )}
        </Map>
    </div>
  )
})

export default YMapComponentPickups