import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import MapComponent from '../complex/MapComponent';
import PickupItem from '../simple/PickupItem';
import useFetchItems from '../../hooks/useFetchItems';
import DeliveUserItems from '../complex/DeliveUserItems';
import DeliveryUserCards from '../complex/DeliveryUserCards';
import { toast } from 'react-toastify';
import MapComponentPickups from '../complex/MapComponentPickups';
import Utils from '../../services/utils';
import YMapsComponent from '../complex/YMapsComponent';
import { YMaps } from '@pbe/react-yandex-maps';
import YMapComponentPickups from '../complex/YMapComponentPickups';

const DeliverySelectModal = observer(({show, setShow}) => {
    const {app, user} = useContext(Context);
    const methods = useForm();

    const [activeItem, setActiveItem] = useState();
    const [pickupItem, setPickupItem] = useState();
    const [deliveryZones, setDeliveryZones] = useState();
    const [address, setAddress] = useState();
    const [addressValue, setAddressValue] = useState('');
    const [location, setLocation] = useState();

    const [activeId, setActiveId] = useState();

    const { items: deliveries } = useFetchItems(UserServices.cart.order.deliveries);
    const { items: pickupAddresses } = useFetchItems(UserServices.cart.order.pickupAddresses);

    useEffect(() => {
        if(deliveries?.length >= 1){
            const deliveryItem = deliveries[0];
            if(deliveryItem){
                setActiveItem(deliveryItem.slug);
                methods.setValue('delivery_id', deliveryItem.id);
                setActiveId(deliveryItem.id);
            }
        }
    }, [deliveries])

    const handleClose = () => setShow(false);

    const validateDeliveryZone = (addressObj) => {
        let result = null;
        deliveryZones.forEach(zone => {
            if (Utils.isMarkerInsidePolygon(
                [addressObj?.lat, addressObj?.lng],
                zone.zones
            )) {
                result = zone.title;
            }
        });

        if (deliveryZones.length > 1 && !result) {
            toast.error(app.localizationsItems?.address_no_zone);
            return false;
        }

        return true;
    };

    const CustomSubmit = async () => {
        const deliveryType = methods.getValues('delivery_id');
        const addressId = methods.getValues('address_id');
        let newData = {}

        if (activeItem === 'self') {
            newData = addressId ? JSON.parse(addressId) : {
                pickup_id: pickupItem?.id,
                full_address: pickupItem?.title,
                location: pickupItem?.location
            };
        }else{
            if(user.token){
                const tempAddress = addressId ? JSON.parse(addressId) : null;
                if (!validateDeliveryZone(tempAddress?.json_address)) return;
                newData = tempAddress;
            }else{
                if (!validateDeliveryZone(address)) return;
                newData = {
                    json_address: {
                        entrance: methods.getValues('entrance') || null,
                        floor: methods.getValues('floor') || null,
                        apartment: methods.getValues('apartment') || null,
                        code: methods.getValues('code') || null,
                        address: addressValue,
                        lat: address?.lat,
                        lng: address?.lng
                    },
                    full_address: addressValue
                };
            }
        }

        user.setAddress({ ...newData, delivery_id: deliveryType });
        setShow(false)
    };

    const handleActiveItem = (name, id) => {
        setActiveItem(name)
        setActiveId(id)
    }

    const fetchDeliveryZones = async () => {
        let str = `delivery_id=${activeId}`; 
        const res = await UserServices.cart.order.deliveryZones();
        
        if(res.statusCode === 200){
            setDeliveryZones(res.content.items);
        }else{
            toast.error(res.message)
        }
    }

    useEffect(() => {
        if(activeId){
            fetchDeliveryZones();
        }
    }, [activeId])

  return (
    <Modal show={show} onHide={handleClose} centered className='address-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <FormProvider {...methods}>
                <div className='address-modal-wrapper'>
                    <div className='address-modal-container'>
                        <div className='address-modal-top'>
                            {deliveries?.map((el, index) =>
                                <div className={activeItem === el.slug ? 'address-modal-top-item active' : 'address-modal-top-item'} onClick={() => handleActiveItem(el.slug, el.id)} key={index}>
                                    <input className="delivery-check-input" required value={el.id} type="radio" 
                                        {...methods.register('delivery_id', {required: true})}
                                    />
                                    {el.name}
                                </div>
                            )}
                        </div>
                        {activeItem === "self" ?
                            <>
                                <h3 className='mob-none'>{app.localizationsItems?.select_pickup}</h3>
                                <PickupItem pickupAddresses={pickupAddresses} setPickupItem={setPickupItem} pickupItem={pickupItem}/>
                                <div onClick={CustomSubmit} className='main-btn'>{app.localizationsItems?.save}</div> 
                            </>
                            :
                                user.token ?
                                    <DeliveryUserCards address={address} 
                                        CustomSubmit={CustomSubmit} 
                                        addressValue={addressValue} 
                                        setAddressValue={setAddressValue}
                                        deliveryZones={deliveryZones}
                                        setLocation={setLocation}
                                    />
                                    :
                                    <DeliveUserItems
                                        CustomSubmit={CustomSubmit} 
                                        addressValue={addressValue} 
                                        setAddressValue={setAddressValue}
                                        setAddress={setAddress}
                                        setLocation={setLocation}
                                    />
                        }
                    </div>
                    {activeItem === "self" ?
                        <div className='map-container'>
                            {app.yandexGeoApiKey?.enabled ?
                                <YMaps query={{ apikey: app.yandexGeoApiKey.params?.key?.value, load: 'geocode' }}>
                                    <YMapComponentPickups setAddress={setAddress} pickupAddresses={pickupAddresses} pickupItem={pickupItem} setPickupItem={setPickupItem}/> 
                                </YMaps>
                                :
                                <MapComponentPickups setAddress={setAddress} pickupAddresses={pickupAddresses} pickupItem={pickupItem} setPickupItem={setPickupItem}/>
                            }
                        </div>
                        :
                        <div className='map-container'>
                            {app.yandexGeoApiKey?.enabled ?
                                <YMaps query={{ apikey: app.yandexGeoApiKey.params?.key?.value, load: 'geocode' }}>
                                    <YMapsComponent setAddress={setAddress} deliveryZones={deliveryZones} setAddressValue={setAddressValue} locationSelect={location}/> 
                                </YMaps>
                                :
                                <MapComponent setAddress={setAddress} deliveryZones={deliveryZones} setAddressValue={setAddressValue} locationSelect={location}/>
                            }
                        </div>
                    }
                </div>
            </FormProvider>
        </Modal.Body>
    </Modal>
  )
})

export default DeliverySelectModal